// Kennel Settings
// -----------------------------------------------------------------------------
$base-url: '~assets/';
$base-font-size: 16px;
$base-line-height: 2;

// Random Variables
// -----------------------------------------------------------------------------
$PI: 3.1415926536;

// Fonts
// -----------------------------------------------------------------------------
$font-serif: 'kepler-std', serif;
$font-display: 'paralucent-condensed', sans-serif;
$font-sans-serif: 'proxima-nova', sans-serif;
$font-mono: 'ibm-plex-mono', monospace;

// Overrides Default Color Palette
// -----------------------------------------------------------------------------
$brand-black: #000;
$brand-white: #fff;

$brand-background-light: #ece5df;
$brand-background-dark: #1d1d1d;

$brand-text: #858585;
$brand-text-contrast: #c4c4c4;

$brand-dropdown-border: #ede6e0;

$brand-blush: #f2b6a5;

$brand-mint: #b4e0c5;
$brand-mint-dark: #358253;

$brand-green: #c2d500;
$brand-green-dark: #707a00;

$brand-beige: #f3e4c6;
$brand-beige-light: #f8efdd;
$brand-beige-lighter: #fbf7ee;

$brand-orange: #e95624;
$brand-orange-dark: #d14830;
$brand-orange-light: rgba($brand-orange, 0.4);
$brand-orange-lighter: rgba($brand-orange, 0.2);

$brand-blue: #5283a3;
$brand-blue-bright: #05b8ff;
$brand-blue-dark: #406a8d;
$brand-blue-darker: #003f77;

$default-colors: (
  social-twitter: #04aceb,
  social-facebook: #3b5998,
  social-linkedin: #0085af,
  social-youtube: #cd201f,
  social-instagram: #e12f67,
  alert-valid: #61a450,
  alert-warning: orange,
  alert-error: #f6482e,
);

// Responsive Settings
// -----------------------------------------------------------------------------
$mq-responsive: true;
$mq-static-breakpoint: l;
$mq-show-breakpoints: (xxs, xs, s, m, l, xl, xxl);
