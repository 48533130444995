// Kennel
@import 'styles/kennel/kennel';

// Vendors (See package.json)
@import 'sass-mq/mq';

// Utils
@import 'styles/utils/variables';
@import 'styles/utils/breakpoints';

.site-header {
  @include position(absolute, 0 null null 0);
  @include size(100%, auto);
  z-index: 999;

  .container {
    @include mq($until: 'm') {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 30px;
      padding-bottom: 20px;
    }

    &::after {
      @include position(absolute, null 40px 0);
      @include size(auto, 1px);
      background-color: $brand-beige-light;

      @include mq($until: 'm') {
        background-color: rgba(#3e3e3e, 30);
        content: '';
      }
    }
  }

  &__logo {
    @include position(absolute, 20px null null 50%);
    transform: translateX(-50%);
    // @include position(relative);
    @include size(64px, 64px);
    z-index: 1;
    display: block;
    background-color: $brand-background-dark;
    background-image: image('header/logo@2x.png');
    background-repeat: no-repeat;
    background-size: 57px 57px;
    background-position: 7px 7px;
    @include mq($from: 'm') {
      background-size: 73px 73px;
      @include size(87px, 87px);
      // @include margin(-18px null null -97px);
    }
  }

  &__menu,
  &__items {
    @include mq($from: 'm') {
      display: flex;
      align-items: center;
    }
  }

  &__menu {
    padding-top: 25px;
    padding-bottom: 0;
    justify-content: space-between;
    border-bottom: 1px solid hsla(0, 0%, 24%, 30%);

    @include mq($until: 'm') {
      @include position(fixed, 0 null null 0);
      @include padding(255px 40px 60px 40px);
      @include size(100%);
      z-index: -1;
      display: none;
      flex-direction: column;
      justify-content: flex-start;
      background-color: $brand-background-dark;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  &__items {
    li + li {
      @include mq($from: 'm') {
        margin-left: 32px;
      }

      @include mq($until: 'm') {
        margin-top: 30px;
      }
    }

    + .site-header__items {
      @include mq($until: 'm') {
        margin-top: 30px;
      }
    }
  }

  &__link {
    @include position(relative);
    display: inline-block;
    color: $brand-white;
    font-family: $font-mono;
    font-size: rem(14px);
    font-weight: 600;
    line-height: (18 / 14);
    letter-spacing: -0.8px;
    padding-bottom: 25px;

    @include mq($until: 'm') {
      padding-bottom: 0;
      font-size: rem(18px);
      line-height: (28 / 18);
      letter-spacing: -1px;
    }

    &::after {
      @include position(absolute, null null -1px 0);
      @include size(100%, 1px);
      content: '';
      background-color: $brand-white;
      transform-origin: 100% 50%;
      transform: scaleX(0);
      transition: transform 0.4s $ease-in-out-expo;
    }

    &--accent {
      &:after {
        background-color: $brand-orange;
        transform: scaleX(1);
      }
      &:before {
        background-image: image('button/chevron-right-double-orange.png');
        content: '';
        display: block;
        @include size(18px);
        float: right;
        margin-left: 8px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .no-touchevents &:hover,
    .no-touchevents &:focus {
      &::after {
        transform-origin: 0% 50%;
        transform: scaleX(1);
        transition: transform 0.7s $ease-out-expo;
      }
    }

    &.is-active {
      &::after {
        transform: scaleX(1);
      }
    }
  }

  &__button {
    @include mq($from: 'm') {
      margin-top: -2px;
    }

    @include mq($until: 'm') {
      position: absolute;
      top: 150px;
      right: 40px;
      left: 40px;
    }
  }

  &__hamburger.button {
    @include position(relative);
    @include size(24px);

    @include mq($from: 'm') {
      display: none;
    }

    span {
      @include position(absolute, 50% null null 50%);
      @include size(20px, 2px);
      @include margin(-1px null null -10px);
      background-color: $brand-white;

      &:nth-child(1),
      &:nth-child(2) {
        transition: transform 0.3s $ease-out-quart;
      }

      &:nth-child(1) {
        transform: translateY(-6px);
      }

      &:nth-child(2) {
        transform: translateY(6px);
      }

      &:nth-child(3) {
        transition: opacity 0.3s $ease-out-quart;
      }
    }
  }

  &--open {
    .site-header__menu {
      @include mq($until: 'm') {
        display: flex;
      }
    }

    .site-header__hamburger {
      span:nth-child(1) {
        transform: rotate(-45deg);
      }

      span:nth-child(2) {
        transform: rotate(45deg);
      }

      span:nth-child(3) {
        opacity: 0;
      }
    }
  }
}
