@font-face {
  font-family: 'proxima-nova';
  src: url('/assets/fonts/ProximaNova-Regular.woff2') format('woff2'),
    url('/assets/fonts/ProximaNova-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('/assets/fonts/ProximaNova-RegularIt.woff2') format('woff2'),
    url('/assets/fonts/ProximaNova-RegularIt.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'ibm-plex-mono';
  src: url('/assets/fonts/IBMPlexMono-SemiBold.woff2') format('woff2'),
    url('/assets/fonts/IBMPlexMono-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'paralucent-condensed';
  src: url('/assets/fonts/paralucent.woff2') format('woff2'),
    url('/assets/fonts/paralucent.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
