// Kennel
@import 'styles/kennel/kennel';

// Vendors (See package.json)
@import 'sass-mq/mq';

// Utils
@import 'styles/utils/variables';
@import 'styles/utils/functions';
@import 'styles/utils/breakpoints';

.site-footer {
  @include position(relative);
  @include padding(80px null);
  background-color: $brand-background-dark;
  background-image: image('footer/footer-texture@2x.png');
  background-size: 492px 328px;
  background-position: 0 100%;
  background-repeat: no-repeat;

  @include mq($until: 'm') {
    padding-top: 55px;
    background-size-x: 100%;
  }

  &__socials,
  &__submenu {
    display: flex;
  }

  &__top,
  &__bottom {
    @include mq($from: 960px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__bottom,
  &__socials,
  &__submenu {
    align-items: center;
  }

  &__top {
    padding-bottom: 48px;
    border-bottom: 1px solid rgba($brand-white, 0.2);

    @include mq($until: 960px) {
      padding-bottom: 64px;
    }
  }

  &__bottom {
    align-items: center;
    padding-top: 33px;

    @include mq($until: 960px) {
      padding-top: 48px;
    }

    a,
    p {
      color: $brand-white;
      font-family: $font-mono;
      font-size: rem(12px);
      font-weight: 600;
      line-height: (15 / 12);
      letter-spacing: -0.43px;
    }
  }

  &__column {
    @include mq($from: 960px) {
      flex-basis: gs(3, 2);
    }

    + .site-footer__column {
      @include mq($from: 960px) {
        margin-left: gs(0, 1);
      }

      @include mq($until: 960px) {
        margin-top: 64px;
      }
    }

    &:nth-child(1) {
      @include mq($from: 960px, $until: 'xl') {
        flex-basis: 100%;
        min-width: 100%;
        max-width: 100%;
        margin-bottom: 64px;
      }

      + .site-footer__column {
        @include mq($from: 960px, $until: 'xl') {
          margin-left: 0;
        }
      }
    }
  }

  &__logo {
    max-width: 233px;

    @include mq($until: 960px) {
      margin-bottom: -24px;
    }
  }

  &__title {
    margin-bottom: 24px;
    // color: $brand-green;
    color: $brand-text;
    font-family: $font-mono;
    font-size: rem(10px);
    font-weight: 700;
    line-height: (13 / 10);
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &__items {
    li + li {
      margin-top: 16px;
    }

    a {
      @include position(relative);
      @include padding(null null 2px);
      display: inline-block;
      border-bottom: 1px solid rgba($brand-white, 0.5);
      color: $brand-white;
      font-family: $font-sans-serif;
      font-size: rem(14px);
      font-weight: 600;
      line-height: (18 / 14);
      letter-spacing: -0.5px;

      &::after {
        @include position(absolute, null null -1px 0);
        @include size(100%, 1px);
        content: '';
        background-color: $brand-orange;
        transform-origin: 100% 50%;
        transform: scaleX(0);
        transition: transform 0.4s $ease-in-out-expo;
      }

      .no-touchevents &:hover,
      .no-touchevents &:focus {
        &::after {
          transform-origin: 0% 50%;
          transform: scaleX(1);
          transition: transform 0.7s $ease-out-expo;
        }
      }
    }
  }

  &__socials {
    a {
      @include position(relative);
      @include size(56px);
      display: inline-block;

      .svg {
        @include position(absolute, 0 null null 0);
        @include size(100%);
        opacity: 0.8;
        fill: $brand-white;
        transition: opacity 0.3s $ease-out-quart;
      }

      &::after {
        @include position(absolute, 50% null null 50%);
        @include size(58px);
        @include margin(-29px null null -29px);
        content: '';
        opacity: 0.5;
        background-image: image('footer/footer-circle@2x.png');
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% 100%;
      }

      .no-touchevents &:hover,
      .no-touchevents &:focus {
        .svg {
          opacity: 1;
        }
      }
    }

    li + li {
      margin-left: 12px;
    }
  }

  &__submenu {
    @include mq($until: 960px) {
      margin-bottom: 48px;
    }

    li + li {
      @include mq($from: 960px) {
        margin-left: 70px;
      }

      @include mq($until: 960px) {
        margin-left: 55px;
      }
    }

    a {
      transition: color 0.3s $ease-out-quart;

      .no-touchevents &:hover,
      .no-touchevents &:focus {
        color: $brand-white;
      }
    }
  }

  &::before {
    @include position(absolute, -78px null null 0);
    @include size(100%, 78px);
    content: '';
    background-image: image('footer/footer-border@2x.png');
    background-size: 100% 100%;

    @include mq($until: 'm') {
      background-size: cover;
    }
    // background-repeat: repeat-x;
    // background-position: 50% 0;
    // background-size: auto 100%;
  }
}
