// Kennel
@import 'styles/kennel/kennel';

// Vendors (See package.json)
@import 'sass-mq/mq';

// Utils
@import 'styles/utils/breakpoints';

.container {
  @include size(100vw, auto);
  @include padding(null 40px);
  max-width: 1240px;
  @include mq($from: 's') {
    @include margin(null auto);
  }
}
